<template>
  <div style="padding: 20px">
    <div style="margin: 10px 0 20px 0">
      <el-input
          :size="size"
          v-model="query.userId"
          placeholder="请输入用户id"
          style="width: 160px;margin-right: 10px"
      ></el-input>
      <el-button style="margin:0 10px" type="primary" @click="select(0)">查 询</el-button>
    </div>
    <el-table
        :data="IntegralList"
        border
        :row-style="{height:30+'px'}"
        :cell-style="{padding:30+'px'+ 0 +'px'}"
        class="table"
        ref="multipleTable"
        height="626px"
        header-cell-class-name="table-header"
    >
      <el-table-column show-overflow-tooltip="true" align="center" prop="userId" label="用户ID"/>
      <el-table-column show-overflow-tooltip="true" align="center" prop="userName" label="用户姓名"/>
      <el-table-column show-overflow-tooltip="true" align="center" prop="commodityName" label="奖品名称"/>
      <el-table-column show-overflow-tooltip="true" align="center" prop="sourceName" label="奖品来源"/>
      <el-table-column show-overflow-tooltip="true" align="center" prop="number" label="数量"/>
      <el-table-column show-overflow-tooltip="true" align="center" prop="state" label="状态">
        <template v-slot="scope">
          {{scope.row.state == 0 ? '未发货':'已发货'}}
        </template>
      </el-table-column>
      <el-table-column show-overflow-tooltip="true" prop="createTime" label="创建时间"/>
    </el-table>
    <div class="pagination">
      <el-pagination
          background
          layout="total, prev, pager, next"
          :current-page="query.pageIndex"
          :page-size="query.pageSize"
          :total="pageTotal"
          @current-change="handlePageChange"
      ></el-pagination>
    </div>

  </div>
</template>

<script>
import {selectPrizeDetail}from "@/api/mall.js";
export default {
  name: "PrizeDetail",
  data() {
    return {
      query:{
        isUse: null,
        pageIndex: 0,
        pageSize: 12,
        userId: null
      },
      IntegralList:[],
      pageTotal:0,
    }
  },
  methods:{
    handlePageChange(index) {
      this.select(index);
    },
    select(index){
      if (index){
        this.query.pageIndex = index
      }
      selectPrizeDetail(this.query).then(val=>{
        if (val.code == 200){
          this.IntegralList = val.data.records;
          this.pageTotal = val.data.total
        }
      })
    }
  },
  created() {
    this.select();
  }
}
</script>

<style scoped>

</style>